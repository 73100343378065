import DeleteRuleModal from '@components/SmartSave/Delete/DeleteRule';
import HistoryTransactions from '@components/SmartSave/HistoryTransactions/HistoryTransactions';
import TypesOfRules from '@components/SmartSave/TypesOfRules/TypesOfRules';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';
import { CERDO_BACKGROUND_DARK_GREEN_ICON } from 'images/GCP';
import React, { useState } from 'react';

const Details = () => {
  const [isOpenHistory, setOpenHistory] = useState(false);
  const [isOpenDeleteRule, setOpenDeleteRule] = useState(false);

  return (
    <>
      {' '}
      <TypesOfRules
        amountMonth={50000}
        amountSaved={350000}
        titleHeader={'APV'}
        title="Aporte Recurrente"
        subtitle="Depísitos automáticos de la fecha seleccionada"
        icon={CERDO_BACKGROUND_DARK_GREEN_ICON}
        frequency="Día 5 de cada mes"
        countTransactions={7}
        numberCard={123456789156789}
        onViewClick={() => {}}
        onBackClick={() => {
          navigate(Route.finance + '?#smartSave');
        }}
        onDeleteClick={() => {
          setOpenDeleteRule(true);
        }}
        onClickViewHistory={() => {
          setOpenHistory(true);
        }}
      />
      {isOpenHistory && (
        <HistoryTransactions closeMenu={() => setOpenHistory(false)} />
      )}
      {isOpenDeleteRule && (
        <DeleteRuleModal onClose={() => setOpenDeleteRule(false)} open={true} />
      )}
    </>
  );
};
export default Details;
