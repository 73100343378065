import LayoutSmartSave from '@components/SmartSave/Layout/Layout';
import React, { useState } from 'react';
import useStyles from './HeaderInfoRecurrent.styles';

interface props {
  amountMonth: number;
  amountSaved: number;
  titleHeader: string;
  onBackClick: () => void;
  onEditClick: () => void;
  align?: string;
  onDeleteClick?: () => void;
}

const HeaderInfoRecurrent = ({
  titleHeader,
  amountMonth,
  amountSaved,
  onBackClick,
  onEditClick,
  align,
  onDeleteClick,
}: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  return (
    <LayoutSmartSave
      title={titleHeader}
      amounts
      amountMonth={amountMonth}
      amountSaved={amountSaved}
      onBackClick={onBackClick}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
    >
      <section className={classes.containerAll}>
        <div></div>
      </section>
    </LayoutSmartSave>
  );
};

export default HeaderInfoRecurrent;
