import { MONEY_ICON } from '@images';
import { parseNumber } from '@utils';
import { CALENDAR_GREEN_ICON, MONEY_GREEN_ICON } from 'images/GCP';
import React, { useState } from 'react';
import useStyles from './CardActiveRuleInfoRecurrent.styles';

interface props {
  amount?: number;
  frequency: string;
}

const CardActiveRuleInfoRecurrent = ({ amount, frequency }: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className={classes.amount}>
          <div>
            <img src={MONEY_GREEN_ICON} />
            <span className="title">Cantidad</span>
          </div>
          <div className="subtitle">${parseNumber(amount || 0, 2)}</div>
        </div>
        <div className={classes.line} />
        <div className={classes.amount}>
          <div>
            <img src={CALENDAR_GREEN_ICON} />
            <span className="title">Frecuencia</span>
          </div>
          <div className="subtitle">{frequency}</div>
        </div>
      </div>
    </section>
  );
};

export default CardActiveRuleInfoRecurrent;
