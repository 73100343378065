import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '10px',
    border: '1px solid #008195',
    display: 'flex',
    padding: '1rem',
    height: '5rem',
    maxWidth: '20rem',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem auto',
    cursor: 'pointer',
  },
  icon: {
    background: '#00323c',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    fontWeight: 300,
    width: '160px',
  },
  arrow: {
    width: '1rem',
    color: theme.palette.primary.main,
  },
  disabled: {
    filter: 'grayscale(100%)',
    opacity: '0.3',
    cursor: 'none',
  },
}));
