import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#eaeaea',
    maxWidth: '57.7rem',
    margin: '0 auto',
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '100%',
    },
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  },
  container: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '50rem',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
}));

export default useStyles;
