import React, { useState } from 'react';
import useStyles from './BackOfInfoRecurrent.styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface props {
  onViewClick: () => void;
  title: string;
}

const CardAmountAndFrequencyInfoRecurrent = ({ title, onViewClick }: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  return (
    <section className={classes.containerAll}>
      <div className="flex">
        <div className={classes.flex}>
          <span className="title">{title}</span>
        </div>
      </div>
      <div className="flex">
        <span>Destino</span>
        <span onClick={() => onViewClick()} className="blue">
          Ver en intersiones <ArrowForwardIosIcon />
        </span>
      </div>
    </section>
  );
};

export default CardAmountAndFrequencyInfoRecurrent;
