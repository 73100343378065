import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#ffffff',
    borderRadius: 8,
    maxWidth: '57.7rem',
    margin: '-1.5rem auto 0',
    width: '100%',
    padding: '1rem 1.5rem',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '22rem',
    },
    '& span': {
      display: 'block',
    },
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  },
  container: {
    width: '100%',
    margin: '0rem auto 0',
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
  sectionIcon: {
    color: '#646464',
    fontFamily: 'Montserrat',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '45.7rem',
    margin: '0 auto',
    paddingBottom: '1rem',
    '& .icon': {},
    '& .title': {
      fontSize: '0.875rem',
    },
    '& .subtitle': {
      fontSize: '0.625rem',
    },
  },
  sectionSwitch: {
    padding: '1rem 0.4rem 0 0.4rem',
    color: '#959595',
    display: 'flex',
    gap: '1.2rem',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '45.7rem',
    margin: '0 auto',
    alignItems: 'center',
    '& .active': {
      fontSize: '0.75rem',
    },
  },
  line: {
    width: '100%',
    maxWidth: '57.7rem',
    margin: '0 auto',
    borderBottom: '1px solid #d1d1d1',
  },
}));

export default useStyles;
