import React, { useState } from 'react';
import useStyles from './CardAmountAndFrequencyInfoRecurrent.styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface props {
  countTransactions?: number;
  numberCard?: number;
  onClickViewHistory: () => void;
}

const CardAmountAndFrequencyInfoRecurrent = ({
  countTransactions,
  numberCard,
  onClickViewHistory,
}: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className="flex">
          <div className={classes.flex}>
            <CheckCircleOutlineIcon />
            <span>Depósito automático</span>
          </div>
        </div>
        <div className={classes.line} />
        <div className="flex">
          <span>Desde</span>
          <span>{numberCard}</span>
        </div>
        <div className={classes.line} />
        <div className="flex">
          <span>{countTransactions}</span>
          <span onClick={onClickViewHistory} className="blue">
            Ver historial <ArrowForwardIosIcon />
          </span>
        </div>
      </div>
    </section>
  );
};

export default CardAmountAndFrequencyInfoRecurrent;
