import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  containerAll: {
    height: '100vh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
  },
  background: {
    width: '100%',
    height: '100vh',
    background: 'rgba(0,0,0,0.6)',
    backdropFilter: 'blur(7px)',
    position: 'fixed',
    top: '0',
    zIndex: 1000,
  },
  card: {
    border: '1ps solid #d1d1d1',
    padding: '0.5rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1300)]: {
      margin: '0.5rem auto',
    },
    '& >div:last-child': {
      fontSize: '0.75rem',
      width: '4.5rem',
    },
    '& .amount': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.2rem',
      '& span': {
        display: 'block',
      },
      '& >span': {},
    },
    '& .containerText': {
      width: '10rem',
      gap: '0.2rem',
    },
    '& .icon': {
      borderRadius: 5,
      '& img': {
        width: '3rem',
        height: '3rem',
      },
    },
    '& .title': {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
    '& .subtitle': {
      color: '#959595',
      fontSize: '0.625rem',
    },
    '& .end': {
      textAlign: 'end',
    },
  },
  cardDetail: {
    border: 'none',
    padding: '0.5rem',
    justifyContent: 'space-between',
    margin: '0rem auto',
    [theme.breakpoints.down(1300)]: {
      margin: '0.5rem auto',
    },
    '& >div:last-child': {
      fontSize: '0.75rem',
      width: '4.5rem',
    },
    '& .amount': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.2rem',
      '& span': {
        display: 'block',
        fontSize: '0.875rem',
      },
      '& >span': {},
    },
    '& .containerText': {
      width: '10rem',
      gap: '0.2rem',
    },
    '& .icon': {
      borderRadius: 5,
      background: 'none',
      '& img': {
        width: '3rem',
        height: '3rem',
      },
    },
    '& .title': {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
    '& .subtitle': {
      color: '#959595',
      fontSize: '0.625rem',
    },
    '& .end': {
      textAlign: 'end',
    },
    '& .time': {
      fontSize: '0.625rem!important',
      color: '#00a9c7',
    },
  },
  greyScale: {
    '& img': {
      filter: 'grayscale(100%)',
      opacity: '0.4',
    },
    '& .time': {
      fontSize: '0.625rem!important',
      color: '#959595',
    },
  },
  options: {
    position: 'fixed',
    bottom: '0',
    zIndex: 9999,
    background: 'white',
    boxShadow: '4px 4px 20px 2px gray',
    borderRadius: '20px 20px 0 0',
    minHeight: '30rem',
    width: '100%',
    padding: '1.5rem 0rem 2.5rem',
    [theme.breakpoints.down(1300)]: {
      padding: '1rem 0 1rem',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 300,
    color: '#646464',
    maxWidth: '18rem',
    margin: '0 auto',
    textTransform: 'uppercase',
  },
  closeModal: {
    width: '2rem',
    height: '0.25rem',
    background: 'rgba(0,0,0,0.5)',
    borderRadius: 10,
    margin: '0 auto 1rem',
  },
  button: {
    background: 'white',
    margin: '0 auto',
    display: 'flex',
    position: 'fixed',
    bottom: '1rem',
    width: '100%',
    [theme.breakpoints.down(1300)]: {
      width: '100%',
    },
    '& button': {
      width: '17.9rem',
      margin: '0 auto',
    },
  },
  containerCards: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
