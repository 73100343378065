import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#ffffff',
    borderRadius: 8,
    maxWidth: '57.7rem',
    width: '100%',
    padding: '1rem 1.5rem',
    margin: '1rem auto',
    [theme.breakpoints.up(1300)]: {
      width: '46rem',
    },
    '& span': {
      display: 'block',
    },
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    '& .blue': {
      color: '#00a9c7',
    },
    '& .flex': {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#959595',
      fontSize: '0.75rem',
      padding: '0.5rem 0 0',
      gap: '0.5rem',
      width: '95%',
      margin: '0rem auto',
      [theme.breakpoints.up(1300)]: {
        width: '100%',
        maxWidth: 540,
      },
      '& svg': {
        color: '#00a9c7',
        width: '1.25rem',
      },
    },
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
    padding: '0.5rem',
    '& >div': {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
    '& img': {
      width: '1rem',
    },
    '& .title': {
      color: '#959595',
      fontSize: '0.75rem',
    },
    '& .subtitle': {
      fontFamily: 'Montserrat',
      color: '#646464',
      fontSize: '0.75rem',
    },
  },
  line: {
    width: '90%',
    maxWidth: '57.7rem',
    margin: '0 auto',
    borderBottom: '1px solid #d1d1d1',
  },
  flex: {
    display: 'flex',
    gap: '1rem',
    fontWeight: 300,
    color: '#646464',
    paddingBottom: '0.5rem',
    alignItems: 'center',
    '& .title': {
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
    },
  },
}));

export default useStyles;
