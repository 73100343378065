import { Switch } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './CardDataCardInfoRecurrent.styles';

interface props {
  defaultStatus?: boolean;
  icon: string;
  title: string;
  subtitle: string;
}

const CardDataCardInfoRecurrent = ({
  icon,
  title,
  subtitle,
  defaultStatus,
}: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  return (
    <section className={classes.containerAll}>
      <div className={classes.container}>
        <div className={classes.sectionIcon}>
          <img className="icon" src={icon} />{' '}
          <div>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle}</span>
          </div>
        </div>
        <div className={classes.line} />
        <div className={classes.sectionSwitch}>
          <span className="active">Activar / desactivar regla</span>
          <Switch
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            checked={true}
            onChange={() => {}}
          />
        </div>{' '}
      </div>
    </section>
  );
};

export default CardDataCardInfoRecurrent;
