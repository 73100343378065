import { Route } from '@interfaces';
import { useMediaQuery } from '@material-ui/core';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import BackOfInfoRecurrent from '../InfoRecurrent/HeaderInfoRecurrent/BackOfInfoRecurrent/BackOfInfoRecurrent';
import CardActiveRuleInfoRecurrent from '../InfoRecurrent/HeaderInfoRecurrent/CardActiveRuleInfoRecurrent/CardActiveRuleInfoRecurrent';
import CardAmountAndFrequencyInfoRecurrent from '../InfoRecurrent/HeaderInfoRecurrent/CardAmountAndFrecuencyInfoRecurrent/CardAmountAndFrequencyInfoRecurrent';
import CardDataCardInfoRecurrent from '../InfoRecurrent/HeaderInfoRecurrent/CardDataCardInfoRecurrent/CardDataCardInfoRecurrent';
import HeaderInfoRecurrent from '../InfoRecurrent/HeaderInfoRecurrent/HeaderInfoRecurrent';
import useStyles from './TypesOfRules.styles';

interface props {
  amountMonth: number;
  amountSaved: number;
  title: string;
  subtitle: string;
  icon: string;
  frequency: string;
  countTransactions: number;
  numberCard: number;
  onViewClick: () => void;
  titleHeader: string;
  onBackClick: () => void;
  onClickViewHistory: () => void;
  onDeleteClick?: () => void;
}

const TypesOfRules = ({
  amountMonth,
  amountSaved,
  title,
  subtitle,
  icon,
  frequency,
  countTransactions,
  numberCard,
  onViewClick,
  titleHeader,
  onBackClick,
  onClickViewHistory,
  onDeleteClick,
}: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1300px)');

  return (
    <section className={classes.containerAll}>
      <HeaderInfoRecurrent
        titleHeader={titleHeader}
        amountMonth={amountMonth}
        amountSaved={amountSaved}
        onBackClick={onBackClick}
        onEditClick={() => navigate(Route.editRuleSmartSave)}
        onDeleteClick={onDeleteClick}
      />
      <div className={classes.container}>
        <CardDataCardInfoRecurrent
          title={title}
          subtitle={subtitle}
          icon={icon}
        />
        <CardActiveRuleInfoRecurrent
          amount={amountMonth}
          frequency={frequency}
        />
        <CardAmountAndFrequencyInfoRecurrent
          countTransactions={countTransactions}
          numberCard={numberCard}
          onClickViewHistory={onClickViewHistory}
        />
        <BackOfInfoRecurrent title={title} onViewClick={onViewClick} />
      </div>
    </section>
  );
};

export default TypesOfRules;
