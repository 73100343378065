import CardOption from '@components/Investments/CreateNew/CardOptions/CardOptions';
import { ButtonPrimary } from '@components/Reusables';
import { Route } from '@interfaces';
import { navigate } from 'gatsby';
import {
  CERDO_BACKGROUND_DARK_GREEN_ICON,
  CHECK_SQUARE_COLOR_ICON,
} from 'images/GCP';
import React from 'react';
import { useStyles } from './HistoryTransactions.styles';

interface propsCreate {
  closeMenu: () => void;
}

const HistoryTransactions = ({ closeMenu }: propsCreate) => {
  const classes = useStyles();

  return (
    <section className={classes.containerAll}>
      <section onClick={closeMenu} className={classes.background} />
      <section className={classes.options}>
        <div onClick={closeMenu} className={classes.closeModal} />
        <div className={classes.containerCards}>
          <CardOption
            icon={CERDO_BACKGROUND_DARK_GREEN_ICON}
            title="Nombre destino"
            subtitle="Aporte recurrente"
            onClick={() => {
              navigate(Route.createGoal);
              closeMenu();
            }}
            expensiveVersion
            amount={200000}
            time="01 mes"
            className={classes.card}
          />
          <CardOption
            icon={CHECK_SQUARE_COLOR_ICON}
            title="1era transferencia"
            subtitle="Domingo 12 de Marzo, 2023"
            onClick={() => {
              navigate(Route.createGoal);
              closeMenu();
            }}
            expensiveVersion
            amount={45000}
            time="AHORRADO"
            className={classes.cardDetail}
          />{' '}
          <CardOption
            icon={CHECK_SQUARE_COLOR_ICON}
            title="1era transferencia"
            subtitle="Domingo 12 de Marzo, 2023"
            onClick={() => {
              navigate(Route.createGoal);
              closeMenu();
            }}
            expensiveVersion
            amount={45000}
            time="PROGRAMADO"
            className={`${classes.cardDetail} ${classes.greyScale}`}
          />
        </div>
        <div className={classes.button}>
          <ButtonPrimary text="Editar monto pagos" onClick={() => {}} />
        </div>
      </section>
    </section>
  );
};
export default HistoryTransactions;
