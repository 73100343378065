import React from 'react';
import { useStyles } from './CardOptions.styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { parseNumber } from '@utils';

interface CreateNewProps {
  icon: string;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  onClick: () => void;
  expensiveVersion?: boolean;
  amount?: number;
  time?: string;
  className?: string;
}

const CardOption = ({
  icon,
  title,
  subtitle,
  disabled,
  onClick,
  expensiveVersion,
  amount,
  time,
  className,
}: CreateNewProps) => {
  const classes = useStyles();

  return (
    <section
      onClick={onClick}
      className={`${disabled && classes.disabled} ${
        classes.container
      } ${className}`}
    >
      <div className={`${classes.icon} icon`}>
        <img src={icon} />
      </div>
      <div className={`${classes.containerText} containerText`}>
        <div className={classes.title}>{title}</div>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      </div>
      {expensiveVersion ? (
        <div className="amount">
          <span>${parseNumber(amount || 0, 2)}</span>
          <span>{time}</span>
        </div>
      ) : (
        <ArrowForwardIosIcon className={classes.arrow} />
      )}
    </section>
  );
};
export default CardOption;
