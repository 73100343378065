import React from 'react';

import { makeStyles } from '@material-ui/core';
import { DELETE_ICON } from 'images/GoogleCloud';
import { ButtonPrimary, ButtonText } from '@components/Reusables';

interface AddFundsModalProps {
  onClose: () => void;
}

const DeleteRuleModal = ({ onClose }: AddFundsModalProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.backgroundcontainer} onClick={onClose} />
      <div className={classes.subContainer} onClick={() => {}}>
        <div>
          <div className={classes.title}>
            <img src={DELETE_ICON} />
            <span>Eliminar</span>
          </div>
          <div className={classes.description}>
            ¿Estás seguro que quieres eliminar esta regla de ahorro?
          </div>
          <div className={classes.containerButton}>
            <ButtonText text="Cancelar" onClick={onClose} />
            <ButtonPrimary text="Eliminar" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteRuleModal;
const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      display: 'grid',
      alignItems: 'center',
    },
    backgroundcontainer: {
      backdropFilter: 'blur(1px)',
      position: 'absolute',
      zIndex: 1,
      width: '100vw',
      height: '100vh',
      background: 'rgba(0,0,0,0.6)',
      top: 0,
      display: 'grid',
      alignItems: 'center',
    },
    description: {
      fontSize: '0.75rem',
      paddingTop: '1rem',
    },
    containerButton: {
      display: 'flex',
      justifyContent: 'end',
      paddingTop: '2rem',
      '& button': {
        width: 100,
        minWidth: 100,
        textDecoration: 'none',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      paddingBottom: '1rem',
      borderBottom: '1px solid white',
      '& span': {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        fontSize: '0.875rem',
      },
    },
    subContainer: {
      width: '100%',
      margin: '0 auto',
      zIndex: 9999,
      position: 'absolute',
      display: 'grid',
      justifyContent: 'center',
      '& >div': {
        width: '20rem',
        height: '14rem',
        padding: '2rem',
        borderRadius: 15,
        background: 'rgba(277,277,277,0.8)',
      },
    },
  }),
  { index: 1 }
);
