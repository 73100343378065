import { makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { DELETE_ICON, PEN_WHITE_ICON } from 'images/GoogleCloud';
import { parseNumber } from '@utils';

interface props {
  title?: string;
  children: React.ReactNode;
  amounts?: boolean;
  amountSaved?: number;
  amountMonth?: number;
  onBackClick: () => void;
  onEditClick: () => void;
  onDeleteClick?: () => void;
}

const LayoutSmartSave = ({
  title,
  children,
  amounts,
  amountSaved,
  amountMonth,
  onBackClick,
  onEditClick,
  onDeleteClick,
}: props) => {
  const classes = useStyles();
  const [isSelect, setSelect] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1300px)');

  return (
    <div className={classes.container}>
      <section className={classes.containerAll}>
        <div className={classes.containertwo}>
          {isMobile && (
            <ArrowBackIosIcon onClick={onBackClick} className={classes.arrow} />
          )}
          {isMobile && title}
          {!isMobile && (
            <div className={classes.alignStart}>
              <ArrowBackIosIcon
                onClick={onBackClick}
                className={classes.arrow}
              />
              <div>{title}</div>
            </div>
          )}
          <div className={classes.icons}>
            <img onClick={onEditClick} src={PEN_WHITE_ICON} />
            <div onClick={onDeleteClick} className="containerDelete">
              <img className="second" src={DELETE_ICON} />
            </div>
          </div>
        </div>
        {amounts && (
          <div className={classes.amounts}>
            <div className="column">
              <span className="title">Total ahorrado</span>
              <span className="amountBig">
                ${parseNumber(amountSaved || 0, 2)}
              </span>
            </div>
            <div className="column">
              <span className="amountSmall">
                + ${parseNumber(amountMonth || 0, 2)}
              </span>
              <span className="title end">Este mes</span>
            </div>
          </div>
        )}
      </section>

      {children}
    </div>
  );
};

export default LayoutSmartSave;

const useStyles = makeStyles((theme) => ({
  containerAll: {
    maxWidth: '100%',
    margin: '0 auto',
    width: '100%',
    color: 'white',
    background: '#001e28',
    padding: '2rem 1.5rem',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '100%',
    },
    '& >div': {
      width: '100%',
      margin: '0 auto',
      justifyContent: 'space-between',
      display: 'flex',
      [theme.breakpoints.up(1300)]: {
        maxWidth: '45rem',
      },
    },
    '& .containerDelete': {
      background: '#0a5a64',
      width: '1.4rem',
      padding: '0.2rem',
      borderRadius: 5,
    },
  },

  containertwo: {
    [theme.breakpoints.up(1300)]: {
      maxWidth: '30rem',
    },
  },
  icons: {
    display: 'flex',
    gap: '1rem',
    '& >img': {
      width: '1.4rem',
      padding: '0.2rem',
      borderRadius: 5,
      background: '#0a5a64',
    },
    '& .second': {
      filter: 'invert(100%)',
    },
  },
  amounts: {
    color: 'white',
    padding: '2rem 0',
    width: '90%',
    maxWidth: '57.7rem',
    fontFamily: 'Montserrat',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '100%',
    },
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .title': {
      fontSize: '0.75rem',
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    '& .end': {
      textAlign: 'end',
    },
    '& .amountBig': {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    '& .amountSmall': {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  },
  alignStart: {
    display: 'flex',
    gap: '2rem',
  },
}));
