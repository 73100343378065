import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#eaeaea',
    maxWidth: '57.7rem',
    margin: '0 auto',
    width: '100%',
    padding: '0 1rem',
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
}));

export default useStyles;
