import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerAll: {
    background: '#ffffff',
    borderRadius: 8,
    maxWidth: '57.7rem',
    width: '100%',
    padding: '1rem 0.5rem',
    margin: '1rem auto',
    [theme.breakpoints.up(1300)]: {
      maxWidth: '21rem',
      margin: '-1.5rem auto 0',
      height: 'auto',
      padding: '0rem 1.5rem',
    },
    '& span': {
      display: 'block',
    },
    '& .titleCon': {
      color: '#282828',
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  },
  container: {
    width: '95%',
    margin: '0rem auto',
    [theme.breakpoints.up(1300)]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-evenly',
    },
  },
  background: {
    minHeight: '100vh',
    padding: '2rem',
  },
  heightCardNotAdded: {
    height: '10rem',
    width: '90%',
    margin: '0 auto',
    maxWidth: '45.7rem',
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'space-between',
    padding: '1rem 1rem',
    [theme.breakpoints.up(1300)]: {
      padding: '0rem 1rem',
    },
    '& >div': {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
    '& img': {
      width: '1rem',
    },
    '& .title': {
      color: '#959595',
      fontSize: '0.75rem',
    },
    '& .subtitle': {
      fontFamily: 'Montserrat',
      color: '#646464',
      fontSize: '0.75rem',
    },
  },
  line: {
    width: '90%',
    maxWidth: '57.7rem',
    margin: '0 auto',
    borderBottom: '1px solid #d1d1d1',
  },
}));

export default useStyles;
